<template>
  <tour :steps="tourSteps">
  <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <ConfirmLancarPedidoModal
        :modalShow="modalPedido.showModal"
        :typeModal="modalPedido.typeModal"
        :modalName="'lancarRealizarNovoPedido'"
        @confirmed="saveOrder"
        @canceled="() => modalPedido.showModal = false"
        @confirmedTrhowNewOrder="confirmedTrhowNewOrder"
    />

    <PendenciaModal :showModal="modalPendencia.showModal" :titleText="modalPendencia.titleText"
      :infoText="modalPendencia.infoText" @confim-action="handleConfirmPendencia"
      @reject-action="handleRejectPendencia" />
      <page-header :screenName="screenName" :linkItems="linkItems" />
      <section class="order-details" v-if="!showLoading" id="adesao">

      <b-row v-if="populated">
        <b-col cols="12" xl="9" md="8">
          <b-card no-body class="invoice-preview-card">
            <b-card-body class="order-details-padding pb-0">
              <div class="logo-wrapper mb-3">
                <img :src="logo" alt="VacinaSESI" class="d-inline-block float-left" />

                <h2 v-if="modoVisualizacao" v-text="screenName" class="d-inline-block float-right"></h2>
              </div>
              <!-- countdown-->
              <div class="d-block mb-3" v-if="modoCriacao">
                <b-alert variant="warning" show>
                  <h4 class="alert-heading">
                    Atenção! Ao final do tempo abaixo ou ao clicar em Editar
                    Pedido, as informações da sua adesão serão perdidas.
                    Finalize a adesão ou crie uma nova para alterar valores.
                  </h4>
                  <div class="alert-body countdown">
                    Você tem <strong>{{ displayTime }}</strong> para finalizar
                    sua adesão.
                  </div>
                </b-alert>
              </div>
              
              <div class="
                    d-flex
                    justify-content-between
                    flex-md-row flex-column
                    invoice-spacing
                    mt-0
                  ">
                <div v-if="responsible != null" class="d-inline-block float-left">
                  <p class="card-text mb-25 mt-2">
                    <strong>{{ campaignDescription }}</strong>
                  </p>
                </div>

                <div v-if="modoVisualizacao" class="d-inline-block float-right mt-2">
                  <p class="m-0 text-right">
                    Data da realização: <strong>{{ order.dateAdhesion }}</strong>
                  </p>
                </div>
              </div>
              <div class="d-block mt-2" v-if="canceledAdhesion">
                <b-alert class="p-1" variant="danger" show>
                  <strong>Atenção! Esta é uma adesão cancelada.</strong>
                </b-alert>  
              </div>
            </b-card-body>
            <b-card-body v-if="order.sesi_responsavel != null" class="order-details-padding pt-0 pb-0">
              <div class="mb-2">
                <p class="card-text mb-25 mt-3">
                  {{ order.sesi_responsavel.descricao }}
                </p>
                <p class="card-text mb-25 mt-2">
                  {{adhesion ? adhesion.empresa_endereco.descricao : company.descricao}}
                </p>
                <p class="card-text mb-25">
                  {{adhesion ? adhesion.empresa_endereco.logradouro : company.logradouro}}, n° {{adhesion ? adhesion.empresa_endereco.numero : company.numero}}, {{ adhesion ? adhesion.cidade.nome : company.cidade}},
                  {{adhesion ? adhesion.cidade.uf : company.uf}}, {{adhesion? adhesion.cep :    company.cep | VMask("#####-###")}}

                </p>
                <p class="card-text mb-25" v-if="order.sesi_responsavel.contato != null">
                  {{ order.sesi_responsavel.contato.email_responsavel }}
                </p>
              </div>
            </b-card-body>

            <hr class="mx-2"/>
            <b-card-body v-if="company != null" class="order-details-padding pt-0 pb-0 mt-2">
              <div class="mb-2">

                <h6 v-if="company.documento.length == 14">CNPJ {{company.documento | VMask('##.###.###/####-##')}}</h6>
                <h6 v-else>CPF {{company.documento | VMask('###.###.###-##')}}</h6>
                <p class="card-text mb-1">
                  {{ company.nome_empresa }}
                </p>

                <h6 v-if="company.empresa_endereco">{{adhesion ? adhesion.empresa_endereco.descricao : company.descricao}}</h6>
                <p class="card-text mb-25" v-if="adhesion">
                  {{ adhesion.logradouro }}, {{ adhesion.bairro }}
                </p>
                <p class="card-text mb-25" v-if="adhesion && adhesion.cidade">
                  {{ adhesion.cidade.nome }}/{{ adhesion.cidade.uf }}
                </p>
                <p class="card-text mb-25" v-if="address">
                  {{ address.nome }}/{{ address.uf }}
                </p>
              </div>
            </b-card-body>

            <b-card-body v-if="company != null" class="order-details-padding pt-0">
              <div class="mb-4">
                <h6>Aos cuidados:</h6>
                <p class="mb-25 font-weight-bold" v-if="company.empresa_contato != null">
                  {{ contact_adress ? contact_adress.nome : company.responsavel }}
                </p>
                <p class="card-text mb-25" v-if="company.empresa_contato != null">
                  {{ contact_adress ? contact_adress.email : company.email }}
                </p>
                <p class="card-text mb-25" v-if="company.empresa_contato != null">
                  {{ contact_adress ? contact_adress.telefone : company.telefone_empresa | VMask('## ?####-####')}} 
                </p>
              </div>
            </b-card-body>

            <div v-if="doses != null">
              <b-table-lite responsive :items="doses" :fields="fields">
                <template #cell(vacina_nome_tecnico)="data">
                  <b-card-text class="font-weight-bold mb-25">
                    {{ data.item.vacina_nome_tecnico }} <br />
                  </b-card-text>
                  <b-card-text class="text-nowrap">
                    {{ data.item.nome_categoria }}
                  </b-card-text>
                </template>

                <template #cell(id_incentivo)="data">
                  Lote {{ (data.item.id_incentivo == 1 ? lotePadrao : lotePromocional) }}
                </template>
                
                <template #cell(custo_unitario)="data">
                  {{ formatCurrency(data.item.custo_unitario) }}
                </template>

                <template #cell(valor)="data">
                  {{ formatCurrency(data.item.valor) }}
                </template>
              </b-table-lite>
            </div>

            <b-card-body class="order-details-padding pb-0 mb-2">
              <div class="order-totals">
                <p>Quantidade total:</p>

                <p class="order-totals-value mr-2">
                  {{ getSumTotalQtd }} doses
                </p>

                <p>Total:</p>

                <p class="order-totals-value">
                  {{ getTotals }}
                </p>

              </div>
            </b-card-body>


            <b-card-body class="order-details-padding pb-0 mb-4">
              <b-card-text class="mb-25 d-flex align-items-center">
                <strong class="mr-2">
                  Atenção!
                </strong>
                <span>
                  Os valores acima podem sofrer alterações, de acordo com as condições estabelecidas nas regras de contratação. 
                  Consulte o termo de adesão para mais detalhes e, em caso de dúvidas, entre em contato com o SESI.
                </span>
              </b-card-text>
              <b-card-text class="mb-25">
              </b-card-text>
            </b-card-body>

            <b-card-footer class="order-details-padding pt-0">
              <div class="order-details-footer">
                <div class="order-details-emit">
                  <p>
                    <span class="order-details-footer-title">Pedido realizado por:</span>
                    {{ issuedBy }}
                  </p>
                </div>
                <div class="order-details-canceled-container mb-2" v-if="canceledAdhesion">
                    <p>
                      <span class="order-details-footer-title">Adesão cancelada por:</span>
                      {{ canceledBy }}
                    </p>
                    <p>
                      <span class="order-details-footer-title">Data/Hora do cancelamento:</span>
                      {{ canceledDate }}
                    </p>
                  </div>

                <div>
                  <h6 class="order-details-footer-title">Observações</h6>
                  <p v-text="order.observation" class="mb-3"></p>
                  <h6 v-if="adhesionNumber" class="order-details-footer-title">Este pedido refere-se a adesão #{{
                    adhesionNumber }}</h6>
                </div>
              </div>
            </b-card-footer>
          </b-card>
        </b-col>

        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <b-card class="order-details-pd-btn" v-if="modoCriacao">
            <button type="button" class="btn custom-outline-blue custom-blue-shadow mb-75 w-100"
              @click="editOrderOrBack()">
              Editar o Pedido
            </button>

            <div class="mb-75">
              <b-form-checkbox v-model="readandAcceptTerm" class="contract">
                Li e aceito o
                <a :href="urlContract" target="_blank">Termo de Adesão</a>
              </b-form-checkbox>
            </div>

            <!-- Button: DOwnload -->
            <b-button
              variant="custom-blue"
              @click.prevent="openModalPedido"
              :disabled="getDesabilitarEnvio"
              block
            >
              <span class="align-middle">Finalizar Adesão</span>
              <span v-if="loadingSaving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
            </b-button>
          </b-card>
          <b-card class="order-details-pd-btn" v-if="modoVisualizacao">
            <b-button variant="btn custom-outline-blue" v-if="term" block @click.prevent="visualizarTermo">
              Visualizar termo
            </b-button>
            <b-button variant="outline-danger" class="custom-outline-red btn" block @click="openModalPendencia()" v-if="canCancelAdhesion && !canceledAdhesion">
              Cancelar adesão
            </b-button>
            <b-button variant="custom-blue" block @click="editOrderOrBack()">
              Voltar
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <NotificacoesModal
          :previues="previues"
      />
      <PdfAdesaoModal :modalShow="showModal.show" :pdf="pdf" @fechar="fecharModal" />
    </section>
  </b-overlay>
  </tour>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardFooter,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import { format } from "@/helpers/money";
import logo from "@/assets/custom-images/vacina-sesi-logo-azul.svg";
import Ripple from "vue-ripple-directive";
import {
  AdesaoGetValidacaoDosesEmpresa,
  AdesaoSalvarPedido,
  AdesaoSetValidacaoDosesEmpresa,
  AdesaoGetChooseOrder,
  AdesaoGetObservacoesDasEmpresas,
  AdesaoGetChooseCompany,
  AdesaoGetChooseCampaign, AdesaoSetChooseCampaign,AdesaoSetChooseCompany
} from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";
import { getUserData } from "@/auth/utils";
import moment from "moment";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import NotificacoesModal from "@/views/custom-pages/adesao/components/NotificacoesModal/NotificacoesModal.vue";
import platform from "platform";
import $store from "@/store";
import PdfAdesaoModal from "@/views/custom-pages/adesao/components/PdfAdesaoModal/PdfAdesaoModal.vue";
import PendenciaModal from '@/views/components/custom/modals/adesao/PendenciaModal.vue';
import ConfirmLancarPedidoModal from '@/views/components/custom/modals/ConfirmLancarPedidoModal.vue';
import { actions, subjects } from "@/libs/acl/rules";
import detailsPurchaseMixin from "../../tour-mixins/detailsPurchaseMixin";

export default {
  title: "Ver Adesão Detalhes",

  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardFooter,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormCheckbox,
    PageHeader,
    NotificacoesModal,
    PdfAdesaoModal,
    BOverlay,
    PendenciaModal,
    ConfirmLancarPedidoModal
  },
  mixins: [detailsPurchaseMixin],
  data() {
    return {
      lotePromocional: 1,
      lotePadrao: 2,
      showModal: {
        show: false,
      },
      modalPendencia: {
        showModal: false,
        titleText: "Você confirma o cancelamento?",
        infoText: 'Ao clicar em "Sim", você estará cancelando esta adesão. As doses aqui não estarão mais reservadas para esta empresa e poderão ser utilizadas em novas adesões. Esta ação não pode ser desfeita.',
      },
      
      pdf: {
        blob: null,
        name: null,
      },
      logo,
      adhesionNumber: null,
      campaignDescription: AdesaoGetChooseCampaign().descricao,
      showLoading: true,
      populated: false,
      readandAcceptTerm: false,
      adhesionHasError: false,
      urlContract: null,
      screenName: "Aderir Campanha",
      fields: [
        { key: "vacina_nome_tecnico", label: "VACINA | CATEGORIA", class: "text-left" },
        { key: "id_incentivo", label: "LOTE", class: "text-center" },
        { key: "custo_unitario", label: "VALOR DA DOSE", class: "text-center" },
        { key: "qtd_distribuida", label: "QTD DE DOSES", class: "text-center" },
        { key: "valor", label: "VALOR TOTAL", class: "text-center" },
      ],

      clockCount: 0,
      count: 0,
      delayRender: 2000,
      termo: [],

      linkItems: [
        {
          name: "Adesão",
          routeName: "purchase",
        },
        {
          name: "Aderir Campanha",
          routeName: "",
        },
        {
          name: "Ver Detalhes",
          active: true,
        },
      ],

      modoCriacao: true,
      modoVisualizacao: false,
      timeLeft: null,
      dataAdesaoGetValidacaoDosesEmpresa: {},
      canceledAdhesion: false,
      canCancelAdhesion: false,
      modalPedido: 
        {
          showModal: false,
          typeModal: 'info'
        },
      loadingSaving: false,
      processando: false,
      campaign: null,
      companyOrder: null,
      previues: false,
    };
  },

  beforeMount() {
    let modoVisualizacao = this.$route.name == "visualizar-adesao-empresa";
    this.modoCriacao = !modoVisualizacao;
    this.modoVisualizacao = modoVisualizacao;
    this.campaign = AdesaoGetChooseCampaign()
    this.companyOrder = AdesaoGetChooseCompany()
    this.canCancelAdhesion = this.verifyPermissions(actions.INSERIR)

    if(this.loadData){
      this.beforeMountInit();
    }
  },

  created() {
    this.startClock();
  },

  methods: {
    beforeMountInit(){
      if (this.modoVisualizacao) {
        this.setAdesaoSetValidacaoDosesEmpresa();
      } else {
        let aGVDE = AdesaoGetValidacaoDosesEmpresa(),
          adesaoObservacoes = AdesaoGetObservacoesDasEmpresas().observacoes;

        this.setUrlBlobContract();

        if (adesaoObservacoes[aGVDE.empresa.id_empresa] != undefined) {
          aGVDE.observation = adesaoObservacoes[aGVDE.empresa.id_empresa];
        }

        this.order = aGVDE;
        this.populated = true;
        this.setTimers();
      }
    },
    formatCurrency(valor) {
      return format(valor);
    },
    async saveOrder() {
      this.loadingSaving = true;
      this.openModalPedido();
      this.setTermo();
      $store.dispatch("adesaoState/setTermo", this.termo);
      await AdesaoSalvarPedido(false, true, true, true);
      this.modalPedido.showModal = false;
      this.loadingSaving = false;
    },
    async confirmedTrhowNewOrder() {
      await this.saveOrder()
      AdesaoSetChooseCampaign(this.campaign)
      AdesaoSetChooseCompany(this.companyOrder)
      this.campaign = null
      this.unit = null
      this.previues = !this.previues;
      this.previues = true;
    },
    editOrderOrBack() {
      let name = this.modoCriacao ? "purchase" : "acceptances";
      this.$router.push({ name }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    setTermo() {
      const items = {
        so: platform.os.family,
        versao_so: platform.version,
      };
      return this.termo.push(items);
    },
    startClock: function () {
      this.countdownID = setInterval(this.countDown, 1000);
    },
    countDown: function () {
      this.count--;
      if (this.count <= 0) {
        this.stopClock();
      }
    },
    stopClock: function () {
      clearInterval(this.countdownID);
      this.countdownID = undefined;
    },
    setTimers() {
      this.showLoading = true;
      let timeLeft = 0;

      if (this.timeLeft != null || AdesaoGetValidacaoDosesEmpresa() == null) {
        timeLeft = this.timeLeft;
      } else {
        timeLeft = parseInt(
          (AdesaoGetValidacaoDosesEmpresa().expira_em - moment().valueOf()) /
          1000
        );
      }

      this.clockCount = timeLeft > 0 ? timeLeft : 0;
      this.count = timeLeft > 0 ? timeLeft : 0;

      this.showLoading = !this.showLoading;
    },
    modalError(text, title = "Erro ao consultar a adesão") {
      this.$swal({
        title,
        text,
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "Ok",
        allowEscapeKey: false,
        allowOutsideClick: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.value) {
          this.editOrderOrBack();
        }
      });
    },
    async setAdesaoSetValidacaoDosesEmpresa() {
      let chooseOrder = AdesaoGetChooseOrder(),
        rota = this.$api.pedidoAdesaoPrecificacoes(chooseOrder.id_pedido),
        params = {
          id_empresa: chooseOrder.id_empresa,
          id_adesao: chooseOrder.id_adesao,
          id_campanha: AdesaoGetChooseCampaign().id_campanha
        };

      await this.$http
        .get(rota, { params })
        .then(({ data }) => {
          data.observation = this.getObservation(data);
          data.dateAdhesion = this.formatDateAdhesion(data.data_prevista);

          if(data.adesao.situacao == 'Cancelado') {
            this.canceledAdhesion = true
          }

          AdesaoSetValidacaoDosesEmpresa(data);
          this.order = data;

          this.adhesionNumber = chooseOrder.id_adesao;
          this.setScreenNameAdhesion(`Pedido #${chooseOrder.numero_pedido}`);
          
          this.populated = true;
          this.timeLeft = 0;
          this.setTimers();
        })
        .catch((err) => {
          this.modalError("Pedido não encontrado!");
        });
    },
    setUrlBlobContract() {
      this.$http
        .get(this.$api.getContract(AdesaoGetChooseCampaign().id_campanha), {
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          this.urlContract = URL.createObjectURL(blob);
        })
        .catch(() => {
          this.adhesionHasError = true;
          this.$helpers.modalErroTermoDeAdesao();
        });
    },
    setScreenNameAdhesion(screenName) {
      this.screenName = screenName;
      this.linkItems = [
        { name: "Adesão", routeName: "acceptances" },
        { name: this.screenName, active: true },
      ];
    },
    formatDateAdhesion(date) {
      return moment(String(date)).format("DD/MM/YYYY");
    },
    getObservation(data) {
      return data.observacao != null && data.observacao.length > 0
        ? data.observacao
        : "---";
    },
    visualizarTermo() {
      const tipoTermo = this.order.termo.tipo;
      this.$http.get(this.$api.downloadAdhesionContract(AdesaoGetChooseOrder().id_campanha), {
        responseType: "blob",
        params: {
          tipo: tipoTermo,
          id_adesao: AdesaoGetChooseOrder().id_adesao
        }
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' });

          this.pdf.blob = URL.createObjectURL(blob);
          this.pdf.nome = response.headers["content-name-file"];
          this.openModal();
        })
        .catch(() => {
          this.$helpers.modalErroServidorPadrao();
        });
    },

    openModal() {
      this.showModal.show = true;
    },

    fecharModal() {
      this.showModal.show = false;
    },

    openModalPedido() {
      this.modalPedido.showModal = true;
    },

    cancelAdhesion() {
      const parameters = {
        id_adesao: AdesaoGetChooseOrder().id_adesao
      }
      this.$http.put(this.$api.cancelAdhesion(), parameters)
        .then(() => { 
            this.closeModalPendencia();
            this.$router.push(
              { 
                name: 'acceptances'
              }
            );
            this.processando = false
        })
        .catch(({ response: { data } }) => {
          this.closeModalPendencia();
          this.modalError(data.errors, 'Erro ao cancelar adesão');
          this.processando = false
        })
    },
    openModalPendencia() {
      this.modalPendencia.showModal = true;
    },
    closeModalPendencia() {
      this.modalPendencia.showModal = false;
    },
    handleConfirmPendencia() {
      if (!this.processando) {
        this.processando = true
        this.cancelAdhesion();
      }
    },
    handleRejectPendencia() {
      this.closeModalPendencia();
    },
    verifyPermissions(action) {
      return this.$can(actions[action], subjects.ADESAO_SESI)
    }
  },

  computed: {
    getSumTotal() {
      if (this.order.doses == null) {
        return 0;
      }

      return this.order.doses
        .map((item) => item.valor)
        .reduce((prev, curr) => prev + curr, 0);
    },
    getSumTotalQtd() {
      if (this.order.doses == null) {
        return 0;
      }

      return this.order.doses
        .map((item) => item.qtd_distribuida)
        .reduce((prev, curr) => prev + curr, 0);
    },
    getTotals() {
      return format(this.getSumTotal);
    },
    getTotalsQtd() {
      return format(this.getSumTotalQtd);
    },
    getDesabilitarEnvio() {
      return this.adhesionHasError || !this.readandAcceptTerm || this.count <= 0 || this.saving
        ? true
        : false;
    },
    displayTime: function () {
      let mins = Math.floor(this.count / 60),
        secs = this.count % 60;

      if (mins <= 0 && secs <= 0) {
        return "00:00";
      }

      if (secs.toString().length < 2) {
        secs = "0" + secs.toString();
      } else {
        secs = secs.toString();
      }

      return mins.toString() + ":" + secs;
    },
    doses() {
      return this.order.doses;
    },
    company() {
      return this.order.empresa;
    },
    responsible() {
      return this.order.sesi_responsavel;
    },
    responsibleEmail() {
      return this.responsible.contato != null && this.responsible.contato.email 
        ? this.responsible.contato.email
        : null
    },
    adhesion() {
      return this.order.adesao;
    },
    term() {
      return this.order.termo;
    },
    address() {
      if (AdesaoGetChooseCompany()) {
        return AdesaoGetChooseCompany().endereco;
      }
    },
    contact_adress() {
      if (AdesaoGetChooseCompany()) {
        return AdesaoGetChooseCompany().contato;
      }

      if(this.order.empresa.empresa_endereco){
        let endereco = this.order.empresa.empresa_endereco.find(e => 
        e.id_cidade === this.order.adesao.id_cidade &&
        e.logradouro == this.order.adesao.logradouro &&
        e.bairro == this.order.adesao.bairro &&
        e.numero == this.order.adesao.numero &&
        e.cep == this.order.adesao.cep
        )
        return endereco ? endereco.contato : null
      }
    },
    issuedBy() {
      return this.order.processado_por != undefined
        ? this.dataAdesaoGetValidacaoDosesEmpresa.processado_por.nome
        : getUserData().fullName;
    },
    canceledBy() {
      return this.order.adesao.situacao == 'Cancelado' 
        ? this.order.adesao.cancelado_por
        : null
    },
    canceledDate() {
      return  moment(String(this.order.adesao.data_alteracao)).format("DD/MM/YYYY HH:mm")
    },
    order: {
      get() {
        return this.dataAdesaoGetValidacaoDosesEmpresa;
      },
      set(field) {
        this.dataAdesaoGetValidacaoDosesEmpresa = field;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "./style.scss";

::v-deep .countdown strong {
  color: inherit;
  font-weight: bold;
}
</style>
<style lang="scss" scoped>
.contract {
  a {
    color: #2772c0;
  }

  .custom-control-input:checked~.custom-control-label::before {
    border-color: #2772c0 !important;
    background-color: #2772c0 !important;
  }
}
.order-details-canceled-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

</style>
