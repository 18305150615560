<template>
    <b-modal
        id="confirm-modal"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
        v-model="modalShow"
    >
        <div class="d-flex flex-column align-items-center justify-center-center my-2">
            <feather-icon
                id="view-modal"
                icon="AlertCircleIcon"
                :class="'text-'+type+ ' mb-2'"
                size="80"
            />
            <div class="text-center">
                <h2>Tem certeza que deseja {{ action }}?</h2>
                <span>{{ infoTextModal }}</span>
            </div>
            <div class="d-flex mt-2">
                <b-button :disabled="loading" variant="primary-button" class="mr-2" @click.prevent="cancelAction">
                    {{ textButtonCancel }}
                </b-button>
                <b-button :disabled="loading" :variant="'outline-'+type" @click.prevent="confirmAction" >
                    <span v-if="loading" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Sim, {{ action }}
                </b-button>
            </div>
          <div class="d-flex mt-2" v-if="modalName == 'lancarRealizarNovoPedido'">
            <b-button :disabled="loading" :variant="'outline-'+type" @click.prevent="confirmActionForThrowNewOrder" >
              <span v-if="loading" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
              Sim, {{ actionThrowNewOrder }}
            </b-button>
          </div>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
export default {
    components: {
        BModal, BButton
    },

    props: {
        modalShow: {
            required: true,
            type: Boolean
        },
        typeModal: {
            required: true,
            type: String
        },
        modalName: {
            required: true,
            type: String
        },
        infoText: {
            type: String
        }
    },

    data() {
        return {
            loading: false,
            textComplement: '',
            modalTypes: [
                {
                    'modalName': 'lancarPedido',
                    'type': 'warning',
                    'action': 'lançar o pedido',
                    'textButtonCancel': 'Cancelar',
                    'infoText': `Após o lançamento esta ação não poderá ser desfeita.`
                },
                {
                    'modalName': 'finalizarPedido',
                    'type': 'warning',
                    'action': 'finalizar a adesão',
                    'textButtonCancel': 'Cancelar',
                    'infoText': ``
                },
                {
                  'modalName': 'lancarRealizarNovoPedido',
                  'type': 'warning',
                  'action': 'finalizar a adesão',
                  'actionThrowNewOrder': 'Lançar e realizar novo pedido',
                  'textButtonCancel': 'Cancelar',
                  'infoText': ``
                }
            ],
            type: '',
            action: '',
            actionThrowNewOrder: '',
            textButtonCancel: '',
            infoTextModal: ''
        }
    },

    methods: {
        confirmAction(modalEvent) {
            modalEvent.preventDefault();

            this.loading = true;

            this.$emit('confirmed', modalEvent);
        },

        confirmActionForThrowNewOrder(modalEvent) {
          this.loading = true;

          this.$emit('confirmedTrhowNewOrder', modalEvent);
        },

        cancelAction(modalEvent) {
            modalEvent.preventDefault();

            this.$emit('canceled', modalEvent);
        }
    },

    watch: {
        modalShow() {
            this.loading = false;

            this.modalTypes.map(item => {
                if(item.modalName == this.$props.modalName) {
                    if(item.modalName == 'lancarRealizarNovoPedido') {
                      this.actionThrowNewOrder = item.actionThrowNewOrder;
                    }
                    this.action = item.action;
                    this.textButtonCancel = item.textButtonCancel;
                    this.infoTextModal = this.$props.infoText ? this.$props.infoText : item.infoText;
                    this.type = item.type;
                }
            });
        }
    }
}
</script>

